import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';

import { CommonBaseComponent } from '../base_component/base.component';
import { CommonAppDataService } from '../app_data/app_data.service';

const FIRST_DOT_REGEX = /^[^.]*\./;

@Component({
	selector: 'common-app-name-component',
	templateUrl: './common-app-name.component.pug',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonAppNameComponent extends CommonBaseComponent {
	firstPart: string = '';
	lastPart: string = '';

	commonAppDataService = this.injector.get(CommonAppDataService);

	constructor(
		protected injector: Injector,
	) {
		super(injector);
		const appData = this.commonAppDataService.getData();

		if (FIRST_DOT_REGEX.exec(appData.appName)) {
			this.firstPart = FIRST_DOT_REGEX.exec(appData.appName)[0];
			this.lastPart = appData.appName.split(FIRST_DOT_REGEX)[1];
		} else {
			this.firstPart = appData.appName;
		}
	}
}
