import { CommonNotificationService } from '@CaseOne/Common/notification/notification.service';
import { APP_INITIALIZER, NgModule } from '@angular/core';

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (commonNotificationService: CommonNotificationService) => {
				return () => commonNotificationService.init();
			},
			multi: true,
			deps: [
				CommonNotificationService,
			],
		},
	],
})
export class CommonNotificationModule {}
