// New version src/Common/pipes/common-list-formatter.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_list_formatter_filter.ts
import { Pipe, PipeTransform } from '@angular/core';
import {
	forEach,
	isString,
	isObject,
} from 'lodash';
import { CommonNAFormatPipe } from './common-na-format/common-na-format.pipe';
import { hasValue } from '@CaseOne/Common/utilities/core.service';

export interface ICommonListFormatterPipeOptions {
	placeholder?: string,
	fields?: string[],
}

@Pipe({
	name: 'commonListFormatter',
})
export class CommonListFormatterPipe implements PipeTransform {
	constructor (
		private commonNAFormatter: CommonNAFormatPipe,
	) {}

	transform(list: any[], options?: ICommonListFormatterPipeOptions): string {
		const result = [];

		forEach(list, (item) => {
			const fields = options?.fields || ['Name'];
			let foundFieldContent = null;

			if (isString(item)) {
				foundFieldContent = item;
			} else if (isObject(item)) {
				forEach(fields, (fieldName) => {
					foundFieldContent = item[fieldName];
					return !foundFieldContent;
				});
			}

			if (hasValue(foundFieldContent)) {
				result.push(foundFieldContent);
			}
		});

		const joinedResult = result.join(', ');

		return joinedResult || options?.placeholder || this.commonNAFormatter.transform(joinedResult);
	}
}
