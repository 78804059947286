import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';
import { hasValue } from '@CaseOne/Common/utilities/core.service';
import { Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root',
})
export class CommonNAFormatPipeService extends CommonBaseService {
	transform(data, toBlank: boolean = false) {
		const ph = toBlank ? '' : '—';
		return !hasValue(data) ? ph : data;
	}
}
