// New version src/Common/pipes/common-na-format.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_na_formatter_filter.ts
//             src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/common_na_to_black_formatter_filter.js
import { Pipe, PipeTransform } from '@angular/core';
import { CommonNAFormatPipeService } from '@CaseOne/Common/pipes/common-na-format/common-na-format.service';

@Pipe({
	name: 'commonNAFormatter',
})
export class CommonNAFormatPipe implements PipeTransform {
	constructor (
		private commonNAFormatPipeService: CommonNAFormatPipeService,
	) {}

	transform(data, toBlank: boolean = false) {
		return this.commonNAFormatPipeService.transform(data, toBlank);
	}
}
