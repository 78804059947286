// New version src/Common/interceptors/error_interceptor.service.ts CommonErrorInterceptor
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/common_errors_interceptor_factory.js CommonErrorsInterceptor

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { get, includes } from 'lodash';
import { CommonNotificationService, ICommonNotyParams } from '../notification/notification.service';
import { CommonLocaleService } from '../locale/locale.service';
import { ICommonResponse, ICommonResponseError } from '../interfaces/response';


@Injectable()
export class CommonErrorInterceptor implements HttpInterceptor {
	protected warningTypes: string[] = ['Success'];

	constructor(
		protected commonNotificationService: CommonNotificationService,
		protected commonLocaleService: CommonLocaleService,
	) {}

	intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isIgnoreNotification = req.headers.get('X-Ignore-Warnings');
		const cloneReq: HttpRequest<any> = req.clone({
			headers: req.headers.delete('X-Ignore-Warnings'),
		});

		return next.handle(cloneReq).pipe(
			map((response: ICommonResponse<any>) => {
				let warningText = get(response, 'body.Warning') || response.Warning;
				let warningHeader = get(response, 'body.WarningHeader') || response.WarningHeader;
				let warningType = get(response, 'body.WarningType') || response.WarningType;
				let warningParams;

				if (!isIgnoreNotification && (warningHeader || warningText || warningType)) {
					warningHeader = this.getWarningHeader(warningHeader);
					warningText = this.getWarningText(warningText);
					warningType = this.getWarningType(warningType);
					warningParams = this.getWarningParams(warningType);
					this.commonNotificationService.show(warningHeader, warningText, warningType, warningParams);
				}

				return response;
			}),
			catchError((response: ICommonResponseError) => {

				// TODO 404 used with custom error messages
				if (response.status !== -1 && response.status !== 404 && (response.status < 200 || response.status > 299)) {
					this.commonNotificationService.handleResponseError(response);
				}

				return throwError(response);
			}),
		);
	}

	protected getWarningHeader (warningHeader: string): string {
		return warningHeader || this.commonLocaleService.instant('common.noty.warning.title');
	}

	protected getWarningText (warningText: string): string {
		return warningText;
	}

	protected getWarningType (warningType: string): string {
		if (warningType === 'OperationIsFullComplete') {
			warningType = 'Success';
		}

		if (warningType === 'OperationIsNotFullComplete') {
			warningType = 'warning';
		}

		if (!includes(this.warningTypes, warningType)) {
			warningType = 'warning';
		}

		return (warningType || '').toLowerCase();
	}

	protected getWarningParams (warningType: string): ICommonNotyParams {
		return null;
	}
}
